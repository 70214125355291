import React, { useEffect, useState } from 'react';
import packageJson from '../package.json';

import './MainFourm.css'

import JurryFourm from "./util/JurryFourm";
import Zone4Title from "./util/Zone4Title";
import HeaderInfoFourm from "./util/HeaderInfoFourm";
import FileInput from "./util/FileInput";
import CSVData from "./util/CSVData";
import NavBar from './NavBar';
import USSASubFourm from './util/USSASubFourm'
import Zone4Header from './util/Zone4Header'
import USSAPointsDownload from "./USSAPointsDownload";
import { parseCSVFile } from './util/CSVParser';

function useLocalStorage(key, initialValue) {
    const savedValue = localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : initialValue;
  
    const [value, setValue] = useState(savedValue);
  
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
  
    return [value, setValue];
  }

function USSAFourm() {
    const APP_VERSION = packageJson.version
    const [file, setFile] = useState(null)
    const [csvData, setCsvData] = useState(null)
    const [jurry, setJurry] = useState(JSON.parse(localStorage.getItem("USSAStoredJury")) || []);
    const [headerInfo, setHeaderInfo] = useState(JSON.parse(localStorage.getItem("USSAHeaderInfo")) || {
        sex: "M",
        nation: "USA",
        discipline: "SP",
        category: "FIS",
        type: "Startlist",
        format: "individualDistance",
        raceDate: new Date().toISOString().slice(0, 10),
        tempunit: "C",
        longunit: "m",
        speedunit: "kmh",
        windunit: "ms",
        timingBy: "Zone4 Systems",
        dataProcessingBy: "Zone4 Systems",
        softWareCompany: "Zone4 Systems",
        eventType:"MS",
        technique:"FR",
    })
    const [forceUpdate, setForceUpdate] = useState(false);

    // VISABILITIES
    const [fileInputVis, setFileInputVis] = useLocalStorage('fileInputVis',true);
    const [headerInfoFourmVis, setHeaderInfoFourmVis] = useLocalStorage('headerInfoFourmVis',true);
    const [jurryFourmVis, setJurryFourmVis] = useLocalStorage('jurryFourmVis',true);
    const [csvDataVis, setCsvDataVis] = useLocalStorage('csvDataVis',true);
    const [ussaFourmVis, setUSSAFourmVis] = useLocalStorage('ussaFourmVis',true);
    const [ussaPointsDownloadVis, setUSSAPointsDownloadVis] = useLocalStorage('ussaPointsDownloadVis', false);

    useEffect(() => {
        if (file) {
            setHeaderInfo((prevHeaderInfo) => ({
                ...prevHeaderInfo,
                "file": file,
                "fileName": file.name,
            }));
            
            parseCSVFile(file)
                .then(({ headers, data, originalData }) => {
                    setHeaderInfo((prevHeaderInfo) => ({
                        ...prevHeaderInfo,
                        "headers": headers,
                        "originalData": originalData
                    }));
                    setCsvData(data);
                    console.log("setCsvData:", data);
                })
                .catch(error => {
                    console.error("Error parsing CSV:", error);
                    // Handle error appropriately
                });
        }
    }, [file]);

    // CONSOLE LOGS:
    useEffect(() => {
        console.log("headerInfo", headerInfo);
    }, [headerInfo]);

    useEffect(() => {
        console.log('jurry:', jurry);
    },[jurry]);

    // Handler to copy Header and Jury from FIS tab
    const copyHeaderAndJuryFromFISTab = () => {
        const fisHeaderInfo = JSON.parse(localStorage.getItem("FISHeaderInfo"));
        const fisJurry = JSON.parse(localStorage.getItem("FISStoredJury"));

        if (fisHeaderInfo && fisJurry) {
            const ussaHeaderInfo = {
                ...headerInfo, // Retain existing USSA-specific fields
                tempunit: fisHeaderInfo.tempunit,
                longunit: fisHeaderInfo.longunit,
                speedunit: fisHeaderInfo.speedunit,
                windunit: fisHeaderInfo.windunit,
                timingBy: fisHeaderInfo.timingBy,
                dataProcessingBy: fisHeaderInfo.dataProcessingBy,
                softWareCompany: fisHeaderInfo.softWareCompany,
                
                eventName: fisHeaderInfo.eventName,
                place: fisHeaderInfo.place,
                nation: fisHeaderInfo.nation,
                category: fisHeaderInfo.category,
                usedFISList: fisHeaderInfo.usedFISList,
                type: fisHeaderInfo.type,

                discipline: fisHeaderInfo.discipline,
                format: fisHeaderInfo.format,
                raceDate: fisHeaderInfo.raceDate,
                technique: fisHeaderInfo.technique,
                
                lapLength: fisHeaderInfo.lapLength,
                lapNumber: fisHeaderInfo.lapNumber,
                hd: fisHeaderInfo.hd,
                mc: fisHeaderInfo.mc,
                tc: fisHeaderInfo.tc,

                sex: fisHeaderInfo.sex,
                codex: fisHeaderInfo.codex,
                startTime: fisHeaderInfo.startTime,

                
                
                
                // Initialize or set USSA-specific properties
                eventType: fisHeaderInfo.eventType || "MS", // Default to "MS" if not present
                NRC: fisHeaderInfo.NRC || "", // Initialize as empty if not present
                distance: fisHeaderInfo.distance || "", // Initialize as empty or map accordingly
            };
 
            setHeaderInfo(ussaHeaderInfo);
            setJurry(fisJurry);
 
            // Update USSA localStorage with the new headerInfo
            localStorage.setItem("USSAHeaderInfo", JSON.stringify(ussaHeaderInfo));
            localStorage.setItem("USSAStoredJury", JSON.stringify(fisJurry));
 
            // Toggle forceUpdate to trigger re-render
            console.log('180')
            setForceUpdate(!forceUpdate);
            console.log('182')
        } else {
            alert("FIS Header or Jury information not found.");
        }
    };

    return (
        <>
        <div className="Centerd-body">
            <div className="fixed-width-box">
                <div className="app-version"></div>
                <Zone4Header version={APP_VERSION}/>
                <NavBar current='USSA'/>
                <Zone4Title title={`Upload CSV`} clickFunction={() => setFileInputVis(!fileInputVis)} status={fileInputVis}/> 
                {fileInputVis && <FileInput setFile={setFile} />}

                {/* New Button to Copy Header and Jury from FIS tab */}
                <button onClick={copyHeaderAndJuryFromFISTab} style={{ marginTop: '20px' }}>
                    Copy Header/Jury from FIS tab
                </button>

                <Zone4Title title="Header Info" clickFunction={() => setHeaderInfoFourmVis(!headerInfoFourmVis)} status={headerInfoFourmVis}/> 
                {headerInfoFourmVis && <HeaderInfoFourm setHeaderInfo={setHeaderInfo} headerInfo={headerInfo} parent={'USSAfourm'}/>}

                <Zone4Title title="Jury" clickFunction={() => setJurryFourmVis(!jurryFourmVis)} status={jurryFourmVis}/> 
                {jurryFourmVis && <JurryFourm jurry={jurry} setJurry={setJurry} parentElement={'USSAfourm'}/>}

                <Zone4Title title="USSA Data Cleanup & XML" clickFunction={() => setUSSAFourmVis(!ussaFourmVis)} status={ussaFourmVis}/> 
                {ussaFourmVis && <USSASubFourm setHeaderInfo={setHeaderInfo} headerInfo={headerInfo} csvData={csvData} setCsvData={setCsvData} jurry={jurry} parent={'USSAfourm'}/>}

                <Zone4Title title="File Preview" clickFunction={() => setCsvDataVis(!csvDataVis)} status={csvDataVis}/> 
                {csvDataVis && <CSVData csvData={csvData} headerInfo={headerInfo}/>}

                <Zone4Title title="Download USSA Points CSV" clickFunction={() => setUSSAPointsDownloadVis(!ussaPointsDownloadVis)} status={ussaPointsDownloadVis}/>
                {ussaPointsDownloadVis && <USSAPointsDownload headerInfo={headerInfo}/>}

                
            </div>
            <div className="rendering-test">rendering test</div>
        </div>
        </>
    )
};

export default USSAFourm