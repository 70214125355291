import React, { useState } from 'react';
import Papa from 'papaparse';
import './css/FileInput.css'
import { isMultiCategoryCSV, extractCategories, extractCategoryData } from './CSVParser';

function FileInput(props){
  const { setFile } = props;
  const [showCategorySelect, setShowCategorySelect] = useState(false);
  const [categories, setCategories] = useState([]);
  const [currentFileData, setCurrentFileData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');

  const downloadIndividualCSVTemplate = () => {

    const makeTemplateCSV = () => {
      const headers = ['rank', 'time', 'status', 'firstname', 'lastname', 'Racer #', 'FIScode', 'USSAcode', 'FISpoints', 'NRLpoints', 'YOB', 'Country', 'Race Points'];
      const csv = Papa.unparse({
        fields: headers,
      });
    
      return csv;
    };


    const csvData = makeTemplateCSV();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'Individual_Template.csv');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const downloadSprintFinalCSVTemplate = () => {
    const makeTemplateCSV = () => {
      const headers = [
          'rank', 
          'time',
          'status', 
          'firstname', 
          'lastname', 
          'Racer #', 
          'FIScode',
          'USSAcode', 
          'FISpoints', 
          'NRLpoints', 
          'YOB', 
          'Country', 
          'Race Points',
          'Highest Level'
      ];
      const rows = Array.from({ length: 30 }, (_, index) => {
        if (index >= 0 && index <= 5) {
          return { 'Highest Level': 'final' };
        } else if (index >= 6 && index <= 11) {
          return { 'Highest Level': 'semifinal' };
        } else if (index >= 12 && index <= 29) {
          return { 'Highest Level': 'quarterfinal' };
        }
        return {}; // default empty object
      });
      const csv = Papa.unparse({
        fields: headers,
        data: rows
      });
    
      return csv;
    };


    const csvData = makeTemplateCSV();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'Sprint_Final_Template.csv');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
  }
  
  const downloadTeamCSVTemplate = () => {
    const makeTemplateCSV = () => {
      const headers = ['rank', 'bib', 'time', 'status', 'teamname', 'nation', 'sex',  'FIScode'];
      const csv = Papa.unparse({
        fields: headers,
      });
    
      return csv;
    };


    const csvData = makeTemplateCSV();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'Team_Template.csv');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
  }

  const handleFileUpload = (file) => {
    if (!file) {
      return;
    }

    setSelectedCategory(''); // Reset category when new file is uploaded

    Papa.parse(file, {
      complete: (results) => {
        if (isMultiCategoryCSV(results)) {
          const extractedCategories = extractCategories(results);
          setCategories(extractedCategories);
          setCurrentFileData(results);
          setShowCategorySelect(true);
        } else {
          // Handle as regular CSV
          setFile(file);
        }
      }
    });
  };

  const handleCategorySelect = (categoryStartIndex) => {
    const selectedCategoryName = categories.find(cat => cat.startIndex === parseInt(categoryStartIndex))?.name || '';
    setSelectedCategory(selectedCategoryName);
    
    const categoryData = extractCategoryData(currentFileData, categoryStartIndex);
    
    // Define the headers we expect in our app
    const expectedHeaders = [
        'rank', 'time', 'status', 'firstname', 'lastname', 
        'Racer #', 'FIScode', 'USSAcode', 'FISpoints', 
        'NRLpoints', 'YOB', 'Country', 'Race Points',
        'Highest Level'
    ];
    
    // Create CSV data with proper structure
    const csvData = {
        fields: expectedHeaders,
        data: categoryData.map(row => ({
            rank: row.rank || '',
            time: row.time || '',
            status: row.status || '',
            firstname: row.firstname || '',
            lastname: row.lastname || '',
            'Racer #': row.racerNumber || '',
            FIScode: row.FIScode || '',
            USSAcode: row.USSAcode || '',
            FISpoints: row.FISpoints || '',
            NRLpoints: row.NRLpoints || '',
            YOB: row.YOB || '',
            Country: row.Country || '',
            'Race Points': row['Race Points'] || '',
            'Highest Level': row.HighestLevel || ''
        }))
    };
    
    // Create new CSV file from structured data
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const newFile = new File([blob], 'selected_category.csv', { type: 'text/csv' });
    
    setFile(newFile);
    setShowCategorySelect(false);
  };

  return(
    <>
      <form id="csvFileUpLoadBox" onSubmit={(e)=> e.preventDefault()}>
        <input 
          id="csvFileInput" 
          type="file" 
          accept=".csv" 
          onChange={(e) => {
            const file = e.target.files[0];
            handleFileUpload(file);
          }}
        ></input>
        {selectedCategory && (
          <div id="selectedCategory" style={{
            marginTop: '10px',
            color: '#777474',
            fontWeight: 'bold',
            fontSize: '16px'
          }}>
            Selected Category: {selectedCategory}
          </div>
        )}
        <div id="instr">Please input a CSV file with the folowing headers. "rank", "time", "status",	"firstname",	"lastname",	"Racer #", "FIS Licence",	"FIS (DI or SP) Points",	"YOB",	"Country",	"Race Points". Or download the example CSV below and replace the category name with your category name and then fill in the data in the corect coloums leaving the row where the category name is blank.</div>
        <div className='button-col'>
          <div className='download-button' onClick={downloadIndividualCSVTemplate}>Download Example CSV Format(Individual Race / Start List)</div>
          <div className='download-button' onClick={downloadSprintFinalCSVTemplate}>Download Example CSV Format (Sprint Final)</div>
          <div className='download-button' onClick={downloadTeamCSVTemplate}>Download Example CSV Format (Team Event)</div>
        </div>
      </form>

      {showCategorySelect && (
        <div className="category-select-overlay">
          <div className="category-select-popup">
            <h3>Select Category to Process</h3>
            <select
              onChange={(e) => handleCategorySelect(parseInt(e.target.value))}
              defaultValue=""
            >
              <option value="" disabled>Select a category...</option>
              {categories.map((category, index) => (
                <option key={index} value={category.startIndex}>
                  {category.name}
                </option>
              ))}
            </select>
            <button onClick={() => setShowCategorySelect(false)}>Cancel</button>
          </div>
        </div>
      )}
    </>
  )
}

export default FileInput;