import Papa from 'papaparse';

// Define column name mappings
const columnMappings = {
    rank: ['rank', 'place', 'position'],
    time: ['time', 'finish time', 'final time'],
    status: ['status', 'result status', 'finish status'],
    firstname: ['firstname', 'first name', 'given name'],
    lastname: ['lastname', 'last name', 'surname', 'family name'],
    racerNumber: ['Racer #', 'bib', 'bib number', 'num', 'racer number', 'Round'],
    FIScode: ['FIScode', 'FIS Licence', 'FISLicence', 'FIS License', 'FISLicense', 'FIS Code', 'FISCode', 'FIS #', 'FIS'],
    USSAcode: ['USSAcode', 'USSA Racing License', 'USSARacingLicense', 'USSA Racing Licence', 'USSARacingLicence', 
               'USSA License', 'USSALicense', 'USSANumber', 'USSA #', 'USSA', 'USSS #', 'USSS', 'USSS Number', 'USSSA Number', 'USSSA #', 'USSSA', 'USSSANumber'],
    FISpoints: ['FISpoints', 'FIS (DI or SP) Points', 'FISPoints', 'FIS Points'],
    NRLpoints: ['NRLpoints', 'USSA Distance', 'USSADistance', 'USSA Points', 'USSAPoints'],
    YOB: ['YOB', 'Birth Year', 'BirthYear', 'Year of Birth', 'YearOfBirth'],
    Country: ['Country', 'Nation', 'NAT', 'Nat'],
    HighestLevel: ['Highest Level', 'HighestLevel', 'Level']
};

// Helper function to find matching column name
const findMatchingColumn = (headers, targetField) => {
    const possibleNames = columnMappings[targetField];
    console.log(`Looking for ${targetField} in headers:`, headers);
    console.log(`Possible names:`, possibleNames);
    const index = headers.findIndex(header => 
        possibleNames.some(name => {
            const headerLower = (header || '').toLowerCase().trim();
            const nameLower = name.toLowerCase().trim();
            console.log(`Comparing '${headerLower}' with '${nameLower}'`);
            return headerLower === nameLower;
        })
    );
    console.log(`Found at index: ${index}`);
    return index;
};

// Function to detect if CSV is multi-category
const isMultiCategoryCSV = (results) => {
    if (!results.data || results.data.length < 2) return false;
    
    // First check if this is a standard template format
    // Standard templates have 'rank' in the first column header
    if (results.data[0][0]?.toLowerCase().trim() === 'rank') {
        return false; // This is a single-category template
    }
    
    // Otherwise, look for category headers in column 0
    return results.data.some((row, index) => {
        if (index === 0) return false; // Skip header row
        return row[0] && row[0].trim().length > 0;
    });
};

// Function to extract categories from CSV
const extractCategories = (results) => {
    const categories = [];
    results.data.forEach((row, index) => {
        if (index === 0) return; // Skip header row
        
        // If first column has content, it's a category header
        if (row[0] && row[0].trim().length > 0) {
            categories.push({
                name: row[0].trim(),
                startIndex: index
            });
        }
    });
    return categories;
};

// Function to extract data for specific category
const extractCategoryData = (results, categoryStartIndex) => {
    // Get the global headers from the first data row (index 0)
    const headers = results.data[0];
    console.log('Processing category starting at index:', categoryStartIndex);
    console.log('Using headers:', headers);
    
    let data = [];
    let currentIndex = categoryStartIndex + 1; // Start immediately after category header
    
    // Continue until we hit next category or end of data
    while (currentIndex < results.data.length) {
        const row = results.data[currentIndex];
        
        // Stop if we hit an empty row (no data in any cell)
        if (!row || row.every(cell => !cell || cell.trim() === '')) {
            break;
        }
        
        const processedRow = processRow(row, headers);
        if (processedRow && Object.keys(processedRow).length > 0) {
            data.push(processedRow);
        }
        
        currentIndex++;
    }
    
    console.log(`Processed ${data.length} rows for this category`);
    return data;
};

// Helper to process individual row
const processRow = (row, headers) => {
    const processedRow = {};
    
    // Map each required field
    Object.keys(columnMappings).forEach(field => {
        const columnIndex = findMatchingColumn(headers, field);
        if (columnIndex !== -1) {
            processedRow[field] = row[columnIndex];
        }
    });
    
    return processedRow;
};

// New function to parse CSV files
const parseCSVFile = (file) => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                try {
                    // Filter out headers that start with _
                    const headers_without_ = results.meta.fields.filter((header) => {
                        return header.charAt(0) !== "_";
                    });

                    // Remove rows where first name and last name are empty
                    let cleanedData = results.data.filter((row) => {
                        return row["firstname"] !== "" && row["lastname"] !== "";
                    });

                    // Remove headers that start with _ from each row
                    cleanedData = cleanedData.filter((row) => {
                        for (const header in row) {
                            if (header.charAt(0) === "_") {
                                delete row[header];
                            }
                        }
                        return row;
                    });

                    resolve({
                        headers: headers_without_,
                        data: cleanedData,
                        originalData: JSON.parse(JSON.stringify(cleanedData))
                    });
                } catch (error) {
                    reject(error);
                }
            },
            error: (error) => {
                reject(error);
            }
        });
    });
};

// Export all functions
export {
    isMultiCategoryCSV,
    extractCategories,
    extractCategoryData,
    processRow,
    parseCSVFile
}; 